import clsx from 'clsx';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import {
    Accordion,
    AccordionBar,
    AccordionBody,
    AccordionItem
} from '../../lib/components/accordion';
import { Grid } from '../../lib/components/layout/grid';
import { List } from '../../lib/components/list';
import { SEO } from '../../lib/components/seo';
import { Heading } from '../../lib/components/typography/heading';
import { Paragraph } from '../../lib/components/typography/paragraph';
import { Box } from '../../lib/components/wrapper/box';
import { WithSidebarLayout } from '../../lib/ui/with-sidebar-layout';
import { coverageAreasRoute } from '../../routes/coverage-areas';

const MetroManila = () => {
    const { pageContent, mmCoverageNumbers } = useStaticQuery(query);

    const table = pageContent.content.find((item: { identifier: string }) => {
        return item.identifier === 'table-metro-manila-covered-hospitals';
    });

    const districts = pageContent.content.filter(
        (item: { identifier: string }) => {
            return (
                item.identifier !== 'table-metro-manila-covered-hospitals' &&
                item.identifier !== 'mm-number-of-covered-hospitals'
            );
        }
    );

    console.log(districts);

    return (
        <>
            <SEO
                title={pageContent.seo.title}
                description={pageContent.seo.description}
                image={pageContent.seo.image.file.url}
            />
            <WithSidebarLayout
                sidebar="coverage"
                sidebarNav={coverageAreasRoute}
                sidebarTitle="Coverage Areas"
                sidebarSticky={true}>
                <Grid item>
                    <Grid
                        container
                        base={false}
                        className={clsx('l-sm:grid-cols-2 l-sm:gap-x-5')}>
                        <Grid
                            item
                            className={clsx(
                                'l-sm:self-start l-sm:sticky l-sm:top-24'
                            )}>
                            <Heading
                                tagName="h1"
                                className="text-lg max-w-18ch">
                                {pageContent.title}
                            </Heading>

                            <Grid
                                container
                                base={false}
                                defaultGap={false}
                                className={clsx(
                                    'gap-2 mt-5',
                                    'm-lg:grid-cols-2'
                                )}>
                                <Box className="grid items-center justify-items-center bg-light-gray">
                                    <Paragraph
                                        className="text-lg leading-normal text-center"
                                        body={false}>
                                        {mmCoverageNumbers.total}
                                    </Paragraph>
                                    <Paragraph className="text-center">
                                        Covered Hospitals
                                    </Paragraph>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <table className="w-full text-center table-fixed">
                                <thead className="bg-light-gray">
                                    <tr>
                                        {table.headers.map(
                                            (item: string, i: number) => {
                                                return (
                                                    <th
                                                        className="p-2 border-divider-light border font-normal"
                                                        key={`table-headers-${i}`}>
                                                        {item}
                                                    </th>
                                                );
                                            }
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {table.tableDatas.map(
                                        (item: {
                                            id: string;
                                            city: string;
                                            numberOfCoveredHospitals: string;
                                        }) => {
                                            return (
                                                <tr key={item.id}>
                                                    <td className="p-2 border-divider-light border">
                                                        {item.city}
                                                    </td>
                                                    <td className="p-2 border-divider-light border">
                                                        {
                                                            item.numberOfCoveredHospitals
                                                        }
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    )}
                                </tbody>
                            </table>
                        </Grid>
                    </Grid>

                    {districts.map(
                        (item: {
                            id: string;
                            district: string;
                            numberOfCoveredHospitals: string;
                            coveredHospitals: [
                                {
                                    id: string;
                                    city: string;
                                    list: [];
                                }
                            ];
                        }) => {
                            return (
                                <Grid
                                    key={item.id}
                                    container
                                    base={false}
                                    className={clsx(
                                        'mt-16 pt-16 border-t border-divider-light',
                                        'l-sm:grid-cols-2 l-sm:gap-x-5'
                                    )}>
                                    <Grid
                                        item
                                        className={clsx(
                                            'l-sm:self-start l-sm:sticky l-sm:top-24'
                                        )}>
                                        <Heading className="text-lg max-w-18ch">
                                            {item.district + ' '}
                                            Covered Hospitals
                                        </Heading>

                                        <Grid
                                            container
                                            base={false}
                                            defaultGap={false}
                                            className={clsx(
                                                'gap-2 mt-5',
                                                'm-lg:grid-cols-2'
                                            )}>
                                            <Box className="grid items-center justify-items-center bg-light-gray">
                                                <Paragraph
                                                    className="text-lg leading-normal text-center"
                                                    body={false}>
                                                    {
                                                        item.numberOfCoveredHospitals
                                                    }
                                                </Paragraph>
                                                <Paragraph className="text-center">
                                                    Covered Hospitals
                                                </Paragraph>
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    <Grid item>
                                        <Accordion>
                                            {item.coveredHospitals.map(
                                                (coveredHospital) => {
                                                    return (
                                                        <AccordionItem
                                                            key={
                                                                coveredHospital.id
                                                            }>
                                                            <AccordionBar
                                                                title={
                                                                    coveredHospital.city
                                                                }
                                                            />
                                                            <AccordionBody>
                                                                <List>
                                                                    {coveredHospital.list.map(
                                                                        (
                                                                            listItem,
                                                                            listItemIndex
                                                                        ) => {
                                                                            return (
                                                                                <li
                                                                                    key={`covered-hospitals-${listItemIndex}`}>
                                                                                    {
                                                                                        listItem
                                                                                    }
                                                                                </li>
                                                                            );
                                                                        }
                                                                    )}
                                                                </List>
                                                            </AccordionBody>
                                                        </AccordionItem>
                                                    );
                                                }
                                            )}
                                        </Accordion>
                                    </Grid>
                                </Grid>
                            );
                        }
                    )}
                </Grid>
            </WithSidebarLayout>
        </>
    );
};

export default MetroManila;

const query = graphql`
    {
        pageContent: contentfulCoverageAreasPage(
            slug: { eq: "/coverage-areas/metro-manila" }
        ) {
            title
            content {
                ... on ContentfulComponentTable {
                    id
                    identifier
                    headers
                    tableDatas {
                        id
                        city
                        numberOfCoveredHospitals
                    }
                }
                ... on ContentfulComponentCoverageNumbers {
                    id
                    identifier
                    total
                }
                ... on ContentfulCoverageAreasMmDistricts {
                    id
                    district
                    numberOfCoveredHospitals
                    coveredHospitals {
                        id
                        city
                        list
                    }
                }
            }
            seo {
                title
                description
                image {
                    file {
                        url
                    }
                }
            }
        }
        mmCoverageNumbers: contentfulComponentCoverageNumbers(
            identifier: { eq: "mm-number-of-covered-hospitals" }
        ) {
            total
        }
    }
`;
